<template>
  <v-app>
    <v-main>
      <v-container fluid class="pa-0">
        <v-row no-gutters justify="center" align="center">
          <v-col cols="12" md="4" class="text-center primary d-none d-md-block" style="height: 100vh">
            <img src="@/assets/logo-zenon-page.png" style="width: 100%">
          </v-col>
          <v-col cols="12" md="4" class="text-center d-md-none">
            <img src="@/assets/logo-zenon-small.png">
          </v-col>
          <v-col cols="12" md="8">
            <v-row justify="center" align="center"
                   v-bind:style="{ height: height + 'vh' }">
              <v-col cols="3">
                <v-alert dense text type="error"
                         v-show="loginError">{{ loginError }}
                </v-alert>
                <v-form ref="loginForm" v-on:submit.prevent="isFormValid('loginForm') && login()">
                  <v-text-field append-icon="mdi-account" clearable dense outlined autocomplete="username"
                                :rules="[rules.required]"
                                v-bind:label="$t('common.username')"
                                v-model="credentials.username"
                                v-on:input="resetLoginError"></v-text-field>
                  <v-text-field append-icon="mdi-key" clearable dense outlined type="password"
                                autocomplete="current-password"
                                :rules="[rules.required]"
                                v-bind:label="$t('common.password')"
                                v-model="credentials.password"
                                v-on:input="resetLoginError"></v-text-field>
                  <v-btn type="submit" :loading="isLoading" color="primary">{{ $t('common.login') }}</v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import formHelper from '@/helpers/form'

export default {
  mixins: [formHelper],
  data: function () {
    return {
      credentials: {
        username: null,
        password: null
      },
      isLoading: false,
      loginError: null
    }
  },
  computed: {
    height () {
      return this.$vuetify.breakpoint.mdAndDown ? 50 : 90
    }
  },
  methods: {
    login () {
      this.isLoading = true
      this.loginError = null
      this.$store.dispatch('login', this.credentials).then(() => {
        this.credentials = {
          username: null,
          password: null
        }
      }).catch(e => {
        if (e.response && e.response.status === 403) {
          this.loginError = this.$t('components.app.bad_credentials')
        } else {
          this.loginError = this.$t('components.app.error')
        }
      }).finally(() => {
        this.isLoading = false
        this.$router.push('/ui')
      })
    },
    resetLoginError () {
      if (this.loginError) this.loginError = false
    }
  }
}
</script>
