import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import en from 'vuetify/es5/locale/en'
import fr from 'vuetify/es5/locale/fr'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { en, fr },
    current: i18n.locale
  },
  theme: {
    themes: {
      light: {
        primary: '#0f527d'
      }
    }
  }
})
