import store from '@/store'

/**
 * Redirecting to the error route
 * @param err Error
 * @param errIntro Introduction for error message
 * @param next Redirection function
 */
const guardErrorCallback = (err, errIntro, next) => next({
  path: '/ui/error',
  query: {
    error: `${errIntro}: ${err}`
  }
})

/**
 * Ensuring that the configuration is init in the store before using it.
 * @param callback What to do after securing config in the store
 * @param next Function to redirect in case of failure.
 */
const secureStoreConfig = (callback, next) => {
  if (store.getters.isConfigUnset) {
    store.dispatch('initConfig')
      .then(callback)
      .catch(err => guardErrorCallback(err, 'Cannot retrieve minimal configuration', next))
  } else {
    callback()
  }
}

const relocateToLogin = () => secureStoreConfig(
  () => {
    const loginPath = store.getters.isZenoAuthUsed ? 'oauth2/authorization/zkeycloak' : '#/ui/login'
    const contextPath = store.getters.getContextPath
    const sep = contextPath.endsWith('/') ? '' : '/'
    window.location.href = `${contextPath}${sep}${loginPath}`
  },
  path => { window.location.href = path }
)

export {
  relocateToLogin
}
