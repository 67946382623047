<template>
  <div class="vz-progress-wave" :style="style">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>

<script>
export default {
  name: 'V-zProgress-wave',
  props: {
    size: {
      type: [Number, String],
      default: 130
    },
    primary: {
      type: Boolean,
      default: false
    },
    opacity: { // between 0 to 100
      type: [Number, String],
      default: 50
    }
  },
  computed: {
    style () {
      return {
        height: this.size + 'px',
        width: this.size + 'px',
        background: this.primary ? this.$vuetify.theme.themes.light.primary + this.percentToHex(this.opacity) : ''
      }
    }
  },
  methods: {
    percentToHex (p) {
      return `0${Math.round((255 / 100) * p).toString(16)}`.slice(-2).toUpperCase()
    }
  }
}
</script>

<style lang="scss">

$wave-color: #f2a91f;

.vz-progress-wave {
  position: relative;
  border-radius: 20%;
  margin: auto;
}

.vz-progress-wave span {
  display: block;
  bottom: 50%;
  width: 9%;
  left: 7.7%;
  background: $wave-color;
  position: absolute;
  animation: audio-wave 1.8s infinite ease-in-out;
}

.vz-progress-wave span:nth-child(2) {
  left: 23%;
  animation-delay: 0.3s;
}

.vz-progress-wave span:nth-child(3) {
  left: 38.5%;
  animation-delay: 0.8s;
}

.vz-progress-wave span:nth-child(4) {
  left: 53.5%;
  animation-delay: 1.00s;
}

.vz-progress-wave span:nth-child(5) {
  left: 69.25%;
  animation-delay: 1.4s;
}

.vz-progress-wave span:nth-child(6) {
  left: 84.6%;
  animation-delay: 2.0s;
}

@keyframes audio-wave {
  0% {
    height: 4%;
    transform: translateY(0%);
  }
  30% {
    height: 77%;
    transform: translateY(50%);
  }
  75% {
    height: 15%;
    transform: translateY(50%);
  }
  100% {
    height: 4%;
    transform: translateY(0%);
  }
}

</style>
