const formHelper = {
  data () {
    return {
      rules: {
        required: value => !!value || this.$t('common.required')
      }
    }
  },
  methods: {
    isFormValid (formName) {
      return this.$refs[formName].validate()
    }
  }
}

export default formHelper
