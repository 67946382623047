import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home.vue'
import Login from '@/components/Login.vue'
import Error from '@/components/Error.vue'
import store from '@/store'

Vue.use(VueRouter)

const launcherRoute = {
  path: '/ui',
  name: 'Launcher',
  alias: '/',
  component: Home,
  meta: { requiresAuthentication: true }
}

const classicLoginRoute = {
  path: '/ui/login',
  name: 'Login',
  component: Login,
  meta: { classicLoginPage: true }
}

const errorRoute = {
  path: '/ui/error',
  name: 'Error',
  alias: '/error',
  component: Error,
  meta: { stopAuthenticating: true }
}

const routes = [
  launcherRoute,
  classicLoginRoute,
  errorRoute
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuthentication)) {
    store.dispatch('routerAuthenticate', next)
  } else if (to.matched.some(record => record.meta.stopAuthenticating)) {
    store.dispatch('notAuthenticatingAnyMore').then(next)
  } else if (to.matched.some(record => record.meta.classicLoginPage)) {
    store.dispatch('secureLogin', next)
  } else {
    next()
  }
})

export default router
