<template>
  <v-app v-if="showWave">
    <VZProgressWave primary opacity="100"/>
  </v-app>
  <router-view v-else/>
</template>

<script>
import { mapState } from 'vuex'
import VZProgressWave from '@/components/V-zProgress-wave'

export default {
  components: { VZProgressWave },
  computed: {
    ...mapState({
      authenticated: state => state.authenticated,
      authenticating: state => state.authenticating,
      useZenoAuth: state => state.config.useZenoAuth
    }),
    showWave () {
      return this.authenticating && !this.authenticated && !this.useZenoAuth && !(this.$route.path === '/ui/login')
    }
  }
}
</script>
