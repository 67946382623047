<template>
  <v-app>
    <v-container>
      <v-row justify="center" align="center" class="pt-4">
        <v-alert :icon="false" type="error">
          <p>
            {{ this.hasErrorMessage ? $t('components.error.has_error') : $t('components.error.no_error') }} :&nbsp;
            {{ errorMessage }}
          </p>
          <v-btn class="mr-2" @click="goHome">{{ $t('components.error.go_home') }}</v-btn>
          <v-btn @click="logout">{{ $t('common.logout') }}</v-btn>
        </v-alert>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Error',
  props: {
    errMsg: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState({
      authenticated: state => state.authenticated
    }),
    errorMessage () {
      if (this.errMsg) {
        return this.errMsg
      } else if (this.$route.query.error) {
        return this.$route.query.error
      } else {
        return this.$t('components.error.unknown_error')
      }
    },
    hasErrorMessage () {
      return this.errMsg || this.$route.query.error
    }
  },
  methods: {
    goHome () {
      this.$router.push('/')
    },
    logout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>
